import React, { Component } from "react";
import IntroVideo from "../assets/videos/ProteinSimpleMauriceDemoIntro.mp4"
import VideoOverlay from "./VideoOverlay";

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onReplayVideo = this.onReplayVideo.bind(this);
  }

  onReplayVideo = () => {
    this.setState({ videoEnded: false });
  };

  render() {
    return (
      <div className="splash-screen">
        <div className="splash-content">
          <div className="splash-screen genomics-logo">
            <VideoOverlay
              child={this.props.child ? this.props.child : null}
              onEnded={this.props.videoEnded}
              video={IntroVideo}
              autoPlay={true}
              controls={true}
              result_menu={true}
              replayVideo={this.onReplayVideo}
              type="scene"
              id={1}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SplashScreen;
