import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import Splash from "./Splash";
import Result from "./Result";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/App.css";
import SceneContainer from "./SceneContainer";
import "../css/index.css";
import TransitionContainer from "./TransitionContainer";

import { CSSTransition, TransitionGroup } from "react-transition-group";

class App extends Component {
  render() {
    return (
      <div className="wrapper">
        <HashRouter>
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  timeout={3000}
                  classNames="fade"
                  key={location.pathname}
                >
                  <Switch location={location}>
                    <Redirect exact from='/' to='/scene/1' />
                    <Route
                      path="/scene/:id/:prev_id?"
                      component={SceneContainer}
                    />
                    <Route path="/results/:id" component={Result} />
                    <Route
                      path="/transition/:id/:width/:height/:prev_id"
                      component={TransitionContainer}
                    />
                    <Route
                      component={({ location }) => {
                        return (
                          <div
                            style={{
                              padding: "50px",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            The page <code>{location.pathname}</code> could not
                            be found.
                          </div>
                        );
                      }}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </HashRouter>
      </div>
    );
  }
}

export default App;
