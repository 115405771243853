/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import Scenes from "../data/scenes.json";
import Results from "../data/results.json";
import HotSpot from "./Hotspot";
import { withRouter } from "react-router";
import { BackBtn, ExternalLinkBtn } from "./Buttons";
import RolloverInstructions from "./RolloverInstruction";
import SlideOut from "./SlideOut";
import ResourceList from "./ResourceList";
import FAQList from "./FAQList";
import ContactUs from "./ContactUs";
import ThankYou from "./ThankYou";

const imgSize = [2500, 1406];

class Scene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      showResources: false,
      showFAQs: false,
      showContactUs: false,
      showThankYou: false,
    };

    this.hotspotClick = this.hotspotClick.bind(this);
  }

  hotspotClick = (event, type, transition, id, width, height) => {
    const { history } = this.props;

    transition
      ? history.push(
          `/transition/${id}/${Number(width).toFixed(2)}/${Number(
            height
          ).toFixed(2)}/${this.props.scene.pk}`
        )
      : history.push(`/${type}/${id}`);
  };

  /**
   * Add event listener
   */
  componentDidMount() {
    this.orientationResize();
    window.addEventListener("resize", this.orientationResize.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.orientationResize.bind(this));
  }

  calcHeight(newWidth) {
    return newWidth * (imgSize[1] / imgSize[0]);
  }

  calcWidth(newHeight) {
    return newHeight * (imgSize[0] / imgSize[1]);
  }

  orientationResize() {
    var dynVidContSize = [window.innerWidth, window.innerHeight];

    var adjHeight = this.calcHeight(dynVidContSize[0]);
    var adjWidth = this.calcWidth(dynVidContSize[1]);

    // If the image is not going to fit height we need to make sure the height doesnt overflow and scale the width
    // adjusted width = <user-chosen height> * original width / original height
    if (adjHeight > window.innerHeight) {
      var newWidth = this.calcWidth(window.innerHeight);
      this.setState((state, props) => {
        return {
          width: newWidth,
          height: window.innerHeight,
        };
      });
    }
    // If the image is going to be to wide, we need to make sure the width doesnt overflow and scale the height
    // adjusted height = <user-chosen width> * original height / original width
    else if (adjWidth > window.innerWidth) {
      var newHeight = this.calcHeight(window.innerWidth);
      this.setState((state, props) => {
        return {
          width: window.innerWidth,
          height: newHeight,
        };
      });
    } else {
      this.setState((state, props) => {
        return {
          width: adjWidth,
          height: adjHeight,
        };
      });
    }
  }

  showResources = () => {
    this.setState({
      showResources: true,
      showFAQs: false,
    });
  };

  hideResources = () => {
    this.setState({ showResources: false });
  };

  showFAQs = () => {
    this.setState({
      showFAQs: true,
      showResources: false,
    });
  };

  showContactUs = () => {
    this.setState({
      showFAQs: false,
      showContactUs: true,
    });
  };

  hideContactUs = () => {
    this.setState({
      showFAQs: true,
      showContactUs: false,
    });
  };

  hideThankYou = () => {
    this.setState({
      showFAQs: true,
      showContactUs: false,
      showThankYou: false,
    });
  };

  contactUsSubmit = () => {
    // this is the form data to send
    this.setState({
      showThankYou: true,
      showContactUs: false,
    });
  };

  hideFAQs = () => {
    this.setState({ showFAQs: false });
  };

  showSpecs = () => {
    const spec = Results.find((result) => result.fields.is_spec);

    if (spec) {
      const { history } = this.props;
      history.push(`/result/${spec.pk}`);
    }
  };

  render() {
    var hotspot_data = [];
    var hotspots = [];

    this.props.scene.fields.results.map((id) => {
      for (var i = 0; i < Results.length; i++) {
        if (id == Results[i].pk) {
          hotspot_data.push(Results[i]);
        }
      }
    });

    // Get children, if there are any
    if (this.props.scene.fields.children.length > 0) {
      this.props.scene.fields.children.map((id) => {
        for (var i = 0; i < Scenes.length; i++) {
          if (id == Scenes[i].pk) {
            hotspot_data.push(Scenes[i]);
          }
        }
      });
    }

    hotspot_data.map((data, index) => {
      hotspots.push(
        <HotSpot
          nextPage={data.pk}
          type={data.type}
          hotspot_x_location={data.fields.hotspot_x_location}
          hotspot_y_location={data.fields.hotspot_y_location}
          tooltip_id={data.fields.tooltip}
          key={index}
          scene_width={this.state.width}
          scene_height={this.state.height}
          onClick={(e) => {
            this.hotspotClick(
              e,
              data.type,
              data.fields.transition,
              data.pk,
              this.state.width,
              this.state.height,
              this.props.match.params.id,
            );
          }}
        />
      );
    });

    const style = {
      backgroundImage: `url(${this.props.backgroundImg})`,
      width: this.state.width,
      height: this.state.height,
      backgroundSize: "cover",
    };

    let backBtn = this.props.scene.fields.is_landing ? null : (
      <BackBtn id={this.props.match.params.prev_id} />
    );
    // let highThroughPut = this.props.scene.fields.is_landing ? (
    //   <HighThroughput />
    // ) : null;

    let rolloverInstructions = this.props.scene.fields.is_landing ? (
      <RolloverInstructions />
     ) : null

    const fadeInOverlay =
      this.state.showResources ||
      this.state.showFAQs ||
      this.state.showContactUs ||
      this.state.showThankYou;

    return (
      <div className="vidContCell d-flex align-self-center">
        <div
          className={`gray-out-overlay ${fadeInOverlay ? "fade-in" : ""}`}
        ></div>
        <div className="scene d-flex align-self-center" style={style}>
          {backBtn}
          {/* {highThroughPut} */}
          <div className="external-links d-flex">
            <ExternalLinkBtn text="Learn More" link="https://www.bio-techne.com/instruments/micro-flow-imaging"> </ExternalLinkBtn>
            <ExternalLinkBtn text="Request Quote" link="https://www.bio-techne.com/p/instruments/micro-flow-imaging-mfi/request-quote"> </ExternalLinkBtn>
          </div>
          {rolloverInstructions}
          {hotspots}
        </div>
        {this.props.scene.fields.is_landing && (
          <React.Fragment>
            {/* <Menu
              showResources={this.showResources}
              showFAQs={this.showFAQs}
              showSpecs={this.showSpecs}
            /> */}
            <SlideOut
              visible={this.state.showResources}
              title="Resources"
              onDismiss={this.hideResources}
            >
              <ResourceList />
            </SlideOut>
            <SlideOut
              visible={this.state.showFAQs}
              title="FAQs"
              onDismiss={this.hideFAQs}
            >
              <FAQList contactUsClick={() => this.showContactUs()} />
            </SlideOut>
            <SlideOut
              visible={this.state.showContactUs}
              title="Ask a question"
              m_auto={true}
            >
              <ContactUs
                onClick={this.hideContactUs}
                handleSubmit={this.contactUsSubmit}
              />
            </SlideOut>
            <SlideOut visible={this.state.showThankYou} m_auto={true}>
              <ThankYou
                onClick={this.hideThankYou}
                title="Thanks for submitting your question!"
                subtitle="We will get back to you shortly"
              />
            </SlideOut>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(Scene);
