import React from "react";
import SplashScreen from "./SplashScreen";
import Scenes from "../data/scenes.json";
import { withRouter } from "react-router";

class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.videoEnded = this.videoEnded.bind(this);
  }

  componentDidMount() {
    this.setState({ elementHeight: this.divRef.clientHeight });
  }

  videoEnded(scene) {
    const { history } = this.props;
    history.push(`/scene/${scene.pk}`);
  }

  render() {
    var landing_scene;

    for (var i = 0; i < Scenes.length; i++) {
      if (Scenes[i].fields.is_landing === true) {
        landing_scene = Scenes[i];
      }
    }

    return (
      <div className="Splash">
        <div
          ref={(element) => (this.divRef = element)}
          className="main-content d-flex justify-content-center transition-enabled"
        >
          <SplashScreen videoEnded={() => this.videoEnded(landing_scene)} />
        </div>
      </div>
    );
  }
}

export default withRouter(Splash);
